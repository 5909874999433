.login-container {
    width: 400px;
    margin: 64px auto 0 auto;
    padding: 16px;
    border-radius: 8px;
}

.login-title {
    text-align: center;
    margin-bottom: 16px;
}

.login-submit-button {
    background: #6e23ff;
    color: white;
    border: none;
    width: 100%;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.login-submit-button:hover {
    background: #bd9bff;
}

.login-submit-button:disabled {
    background: #ccc;
    cursor: auto;
}

.login-register-text {
    text-align: center;
    margin-top: 16px;
}

.login-register-text a {
    text-decoration: none;
}
