.befunge-instructions-table table {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.befunge-instructions-table table td {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.befunge-instructions-table table tbody:nth-child(odd) {
    background: rgba(0, 0, 0, 0.05);
}
