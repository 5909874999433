.ace-github .ace_comment {
    color: #777777;
    font-style: normal;
}

.ace-github .ace_arithmetic {
    color: purple;
}

.ace-github .ace_io {
    color: darkblue;
}

.ace-github .ace_shift {
    color: blue;
}

.ace-github .ace_loop {
    color: #333333;
}
