.gravity-simulation-title {
    text-align: center;
    margin-bottom: 1em;
}

#gravityCanvas {
    width: 100%;
}

.gravity-simulation-control-button {
    margin-right: 12px;
}

.gravity-simulation-parameter-container {
    margin-top: 1em;
}

.gravity-simulation-parameter-container .input-group {
    margin-top: 1em;
}

.gravity-simulation-code {
    margin-top: 1em;
}

.gravity-simulation-code textarea {
    width: 100%;
    height: 300px;
    resize: none;
    padding: 10px;
}

.gravity-simulation-output-container {
    margin-top: 1em;
}

.gravity-simulation-output {
    min-height: 50px;
    max-height: 300px;
    padding: 10px;
    background: #f0f0f0;
    overflow-y: auto;
}
