.tutorial-wrapper {
    height: 100%;
}

.tutorial-content {
    height: 100%;
}

.tutorial-index-col {
    background: rgba(0, 0, 0, 0.05);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    float: left;
    padding: 30px 20px 15px calc(10% + 20px);
    min-width: 200px;
    height: 100%;
    overflow-y: auto;
}

.tutorial-index-item {
    cursor: pointer;
    margin-bottom: 0.5rem;
}

.tutorial-index-subsection {
    margin-left: 20px;
}

.tutorial-index-item-selected {
    font-weight: bold;
    border-left: 4px solid #6e23ff;
    padding-left: 10px;
    margin-left: -14px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.tutorial-instructions-col {
    padding: 30px 10% 15px 20px;
    overflow-y: auto;
    height: 100%;
}

.tutorial-title {
    margin-bottom: 1rem;
}

.tutorial-page-controls {
    margin-top: 48px;
}

.tutorial-previous-button {
    float: left;
    cursor: pointer;
}

.tutorial-next-button {
    float: right;
    cursor: pointer;
}
