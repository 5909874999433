/** IDE **/

.befunge-top-buttons {
    margin-bottom: 10px;
}

.befunge-top-buttons .io-mode-button-group {
    margin-left: auto;
    margin-right: 0;
}

.befunge-ide-button {
    margin-right: 15px;
    min-width: 75px; /* lazy way of making them equal width */
}

.befunge-top-buttons .befunge-delay-slider {
    margin-left: auto;
    margin-right: 0;
}

.befunge-top-buttons .befunge-delay-slider label {
    margin: 0;
}

.befunge-top-buttons .befunge-delay-slider input {
    width: 125px;
    vertical-align: top;
}

.befunge-playfield-bottom {
    border-bottom: 1px solid #e8e8e8;
    position: absolute;
}

.befunge-bottom-buttons {
    margin-top: 10px;
}

.befunge-submit-button {
    margin-left: auto;
    margin-right: 0;
}

.befunge-io-section {
    margin: 10px -15px 0px -15px;
}

.befunge-io-header {
    width: 100%;
}

.befunge-io-input {
    width: 100%;
    height: 100px;
    resize: none;
    padding: 10px;
}

.befunge-io-output {
    min-height: 50px;
    max-height: 300px;
    padding: 10px;
    background: #f0f0f0;
    overflow-y: auto;
}

.befunge-error-dialog {
    width: 100%;
}

/* TODO: does this look good? */
.befunge-current-character {
    border: 1px solid black;
    position: absolute;
    z-index: 100;
    padding: 5px;
    margin-left: -2.5px;
}

.befunge-submission-results {
    max-height: 400px;
    overflow-y: auto;
}

.befunge-stack-wrapper {
    overflow-x: auto;
    margin-bottom: 8px;
}

.befunge-stack {
    white-space: nowrap;
    padding-bottom: 8px;

    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.befunge-stack-cell {
    display: inline-block;
    border: 1px solid black;
    min-width: 50px;
    line-height: 50px;
    padding: 0 8px;
    text-align: center;
}

.befunge-stack-cell:not(:last-child) {
    border-right: 0;
}

.befunge-stack-bottom-label {
    margin-right: 12px;
    line-height: 50px;
}

/** Sandbox **/
.befunge-sandbox-container {
    margin-left: 15px;
    margin-right: 15px;
}
