.brainfuck-top-buttons {
    margin-bottom: 10px;
}

.brainfuck-top-buttons .io-mode-button-group {
    margin-left: auto;
    margin-right: 0;
}

.brainfuck-ide-button {
    margin-right: 15px;
    min-width: 75px; /* lazy way of making them equal width */
}

.brainfuck-top-buttons .brainfuck-delay-slider {
    margin-left: auto;
    margin-right: 0;
}

.brainfuck-top-buttons .brainfuck-delay-slider label {
    margin: 0;
}

.brainfuck-top-buttons .brainfuck-delay-slider input {
    width: 125px;
    vertical-align: top;
}

.brainfuck-bottom-buttons {
    margin-top: 10px;
}

.brainfuck-submit-button {
    margin-left: auto;
    margin-right: 0;
}

.brainfuck-io-section {
    margin: 10px -15px 0px -15px;
}

.brainfuck-io-header {
    width: 100%;
}

.brainfuck-io-input {
    width: 100%;
    height: 100px;
    resize: none;
    padding: 10px;
}

.brainfuck-io-output {
    min-height: 50px;
    max-height: 300px;
    padding: 10px;
    background: #f0f0f0;
    overflow-y: auto;
}

.brainfuck-error-dialog {
    width: 100%;
}

.brainfuck-memory-tape-wrapper {
    overflow-x: scroll;
    margin-bottom: 30px;
}

.brainfuck-memory-tape {
    width: 100%;
    text-align: center;
    table-layout: fixed;
}

.brainfuck-memory-tape td,
.brainfuck-memory-tape th {
    width: 50px;
    box-sizing: border-box;
}

.brainfuck-memory-tape td {
    border: 1px solid black;
    height: 50px;
}

.brainfuck-memory-tape-selected-cell {
    background: yellow;
}

.brainfuck-memory-tape-input-area {
    border: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}

/* TODO: does this look good? */
.brainfuck-current-character {
    border: 1px solid black;
    position: absolute;
    z-index: 100;
    padding: 5px;
    margin-left: -2.5px;
}

.brainfuck-submission-results {
    max-height: 400px;
    overflow-y: auto;
}
