/** CHALLENGES **/

.challenges-header-container {
    width: 100%;
}

.challenges-header-container td {
    vertical-align: middle;
}

.challenges-header-scoreboard-cell {
    text-align: right;
}

.challenges-header-scoreboard-cell a {
    text-decoration: none;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 6px 10px;
}

.challenges-header-scoreboard-cell a:hover {
    text-decoration: none;
    border-color: #6e23ff;
    color: #6e23ff;
}

.challenges-module-deck {
    margin-top: 0.5rem !important;
}

/** CHALLENGES MODULE **/

.challenges-module-page {
    height: 100%;
}

.challenges-module-page h1 {
    margin-bottom: 15px;
}

.challenges-problem-card {
    margin-bottom: 30px;
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.challenges-problem-card:hover {
    background: #fafafa;
    text-decoration: none;
    color: black;
    border-color: #6e23ff;
    color: #6e23ff;
}

.challenges-problem-card-solved {
    border-color: #28a745;
    color: #28a745;
}

.challenges-problem-card-badge {
    margin-right: 8px;
}

.challenges-problem-card .right-detail {
    position: absolute;
    top: 0px;
    right: 30px;
    display: flex;
    height: 100%;
}

.challenges-problem-card .solved-badge {
    margin: auto;
    width: 200px;
    height: 50px;
    border: 1px solid #28a745;
    border-radius: 3px;
    display: flex;
    color: #28a745;
}
.challenges-problem-card .solved-text {
    margin: auto;
}

.challenges-problem-points {
    color: rgba(0, 0, 0, 0.2);
    margin-left: 5px;
}

.challenges-module-content {
    display: flex;
}

.challenges-module-index-col {
    float: left;
    width: 200px;
    padding-left: 15px;
}

.challenges-module-index-col h5:not(:first-of-type) {
    margin-top: 2rem;
}

.challenges-module-problems-col {
    flex: 100%;
    padding: 15px;
    padding-top: 0;
}

.challenges-module-index-col h5 {
    color: #aaa;
}

.challenges-module-collection-index {
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.challenges-module-collection-index-selected {
    font-weight: bold;
}

/** CHALLENGES PROBLEM **/

.challenges-problem-page {
    padding: 15px 10% 0px 10%;
}

.challenges-problem-page-editor {
    margin: 15px;
}

.challenges-problem-page pre {
    background: #f0f0f0;
    padding: 10px;
}

/** SCOREBOARD **/

.scoreboard-header {
    margin-bottom: 0.5em;
}
