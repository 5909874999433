html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#root {
    min-height: 100%;
    position: relative;
}

#body {
    padding-bottom: 50px;
}

.page-body {
    padding: 15px 10% 50px 10%;
}

/** Header and Footer Navs **/
#header {
    background: #6e23ff;
    padding-left: 10%;
    padding-right: 10%;
}

.header-logo {
    width: 30px;
    height: 30px;
    margin-top: -3px;
    margin-right: 5px;
}

#header .navbar-brand {
    font-weight: 700;
}

.header-profile-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

#header .nav-link:not(.active) {
    color: rgba(255, 255, 255, 0.75);
}

#header .nav-link:hover {
    color: #fff;
}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
}
