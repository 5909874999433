.ace-github .ace_arithmetic {
    color: #7b59c0;
}

.ace-github .ace_pcDirection {
    color: #ca402b;
}

.ace-github .ace_pcConditional {
    color: #a65926;
}

.ace-github .ace_halt {
    color: rgb(198, 34, 216);
}

.ace-github .ace_stringMode {
    color: #159393;
}

.ace-github .ace_stack {
    color: #516aec;
}

.ace-github .ace_io {
    color: #bb8a35;
}
