.eso-deck {
    display: flex;
    flex-wrap: wrap;
    margin: -20px;
}

.eso-card {
    width: calc(100% - 70px); /* xs */
    margin: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    cursor: pointer;
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eso-card:hover {
    border-color: #6e23ff;
    color: #6e23ff;
    text-decoration: none;
}

/* sm */
@media (min-width: 576px) {
    .eso-card {
        width: calc(50% - 70px);
    }
}

/* lg */
@media (min-width: 992px) {
    .eso-card {
        width: calc(33.333333% - 70px);
    }
}
