/** CHARACTER TABLE **/

.intercal-character-table-page {
    padding-top: 30px;
    padding-bottom: 30px;
}

.intercal-character-table-title {
    margin-bottom: 30px;
}

.intercal-character-table {
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: unset;
}

.intercal-character-table td {
    width: 120px;
    height: 120px;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;

    /* diable text higlighting (https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting) */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
}

.intercal-character-flipped {
    background: #6e23ff;
    color: white;
}

.intercal-character-symbol {
    font-weight: bold;
    font-size: 26px;
}

.intercal-character-symbol img {
    height: 18px;
}

.intercal-character-name {
    font-size: 18px;
}

.intercal-character-table-toggle-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

/** IDE **/

.intercal-ide {
    margin-left: 15px;
    margin-right: 15px;
}

.intercal-bottom-buttons {
    margin-top: 10px;
}

.intercal-bottom-buttons .btn {
    min-width: 75px;
}

.intercal-run-button {
    margin-left: auto;
    margin-right: 0;
}

.intercal-io-input {
    width: 100%;
    height: 100px;
    resize: none;
    padding: 10px;
}

.intercal-io-output {
    min-height: 50px;
    max-height: 300px;
    padding: 10px;
    background: #f0f0f0;
    overflow-y: auto;
}

.intercal-error-dialog {
    width: 100%;
}

/** SANDBOX **/

.intercal-sandbox-container {
    padding-bottom: 30px;
}

.intercal-sandbox-references {
    margin-top: 10px;
}
