/** IDE **/

.chef-ide {
    margin-left: 15px;
    margin-right: 15px;
}

.chef-bottom-buttons {
    margin-top: 10px;
}

.chef-bottom-buttons .btn {
    min-width: 75px;
}

.chef-run-button {
    margin-left: auto;
    margin-right: 0;
}

.chef-io-input {
    width: 100%;
    height: 100px;
    resize: none;
    padding: 10px;
}

.chef-io-output {
    min-height: 50px;
    max-height: 300px;
    padding: 10px;
    background: #f0f0f0;
    overflow-y: auto;
}

.chef-error-dialog {
    width: 100%;
}

/** ChefMode (Syntax Highlighting) **/
.ace_line .ace_title,
.ace_line .ace_header {
    font-weight: bold;
}

.ace_line .ace_ingredient {
    color: purple;
}

.ace_line .ace_recipe {
    color: green;
}
